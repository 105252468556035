import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["community"],
  "status": ["active"],
  "title": "Along for the Ride",
  "subtitle": "20-man Hardcore Raiding Guild",
  "description": "Hardcore Raiding Guild in World of Warcraft during the Cataclysm expansion.",
  "years": ["2011"],
  "institutions": ["life"],
  "location": "ry",
  "keywords": {
    "topics": ["gaming", "wow"],
    "tools": ["rpi", "python"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Guildmaster"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      